@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";


.blog-detail-wrapper {
  .blog-detail-img {
    padding-top: 29.8%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  .blog-detail-bottom {
    padding: 100px $mainPadding;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .blog-detail-info {
      width: 70%;

      .blog-detail-title {
        color: #292929;
        font-family: Against;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 129.1%;
        letter-spacing: -0.2px;
      }

      .blog-social-wrapper {
        margin-top: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .blog-duration-wrapper {
          display: flex;
          align-items: center;

          .blog-creator-block {
            width: 140px;
            color: #757575;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 0.18px;
            height: 26px;
            overflow: hidden;
          }

          .blog-line {
            width: 1px;
            height: 26px;
            flex-shrink: 0;
            background: #757575;
            margin: 0 8px;
          }

          .blog-duration-block {
            width: 160px;
            height: 26px;
            overflow: hidden;
            color: #757575;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 0.18px;
            display: contents;
            svg {
              width: 20px;
              height: 20px;
              margin-right: 6px;
            }
          }
        }

        .blog-socials {
          display: flex;
          align-self: flex-end;
          align-items: center;
          button{
            p{
              svg {
                width: 40px;
                height: 40px;
                margin-left: 10px;
              }
            }
            &:first-child{
              p{
                svg {
                  width: 40px;
                  height: 40px;
                  margin-left: unset;
                }
              }
            }
          }
          .copy-share{
            cursor: pointer;
            svg{
              width: 40px;
              height: 40px;
              margin-left: 10px;
            }
          }
        }
      }

      .blog-detail-description {
        margin-top: 60px;
        color: #242424;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.06px;

        p {
          color: #242424;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: -0.06px;
        }
      }
    }

    .blog-detail-latest-block {
      width: 94%;
      margin-top: 120px;

      .latest-block-title {
        color: #2E301B;
        text-align: center;
        font-family: Against;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .latest-block-description {
        color: #5B5B5B;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.4px;
        margin-top: 20px;
      }

      .latest-article {
        margin-top: 70px;
      }
    }

    .subscribe-wrapper {
      width: 100%;
      margin-top: 120px;
    }
  }

  @media screen and (max-width: 1050px) {
    .blog-detail-img{
      padding-top: 89.5%;
    }
    .blog-detail-bottom{
      padding: 60px 0;
      .blog-detail-info{
        width: unset;
        padding: 0 $mainPadding 0 $mainPadding;
        .blog-detail-title{
          color: #292929;
          font-family: Against;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: 129.1%;
          letter-spacing: -0.2px;
        }
        .blog-social-wrapper{
          flex-direction: column;
          align-items: unset;
          .blog-socials{
            align-self: flex-start;
            margin-top: 30px;
          }
        }
      }
      .blog-detail-latest-block{
        padding: 100px $mainPadding 0;
      }
    }
  }
}