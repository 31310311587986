@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.about-page-wrapper{
  .aboutUs-block{
    padding: 60px $mainPadding 120px $mainPadding;
    .aboutUs-img-wrapper{
      padding-top: 44%;
      position: relative;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .about-info-block{
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 80px;
      .about-page-title{
        width: 46%;
        color: #2E301B;
        font-family: Against;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .about-page-description{
        width: 48%;
        color: #2E301B;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .aboutUs-block{
      padding: 60px $mainPadding;
      .aboutUs-img-wrapper{
        padding-top: 86.8%;
      }
      .about-info-block{
        flex-direction: column;
        .about-page-title{
          width: 100%;
        }
        .about-page-description{
          width: 100%;
          margin-top: 60px;
        }
      }
    }
  }
}