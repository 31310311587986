@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1002;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  transition: all 0.35s cubic-bezier(.39, .575, .565, 1);
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.40);
  backdrop-filter: blur(3.5px);

  &.expand_on {
    visibility: visible;
    opacity: 1;
  }
  .left-menu-block{
    background-color: white;
    width: 56%;
    height: 100%;
    position: relative;
    .menu-close-block{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 24px 24px 10px 24px;
      svg{
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .menu-wrapper{
      padding-left: 90px;
      display: flex;
      flex-direction: column;
      .menu-title{
        color: #5B5B5B;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 16px;
      }
      a{
        color: #2E301B;
        font-family: Against;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
        text-decoration: unset;
        &:hover{
          color: #5B5B5B;
          margin-left: 4px;
          transition: 0.2s;
        }
      }
      .overflow{
        pointer-events: none;
      }
    }
    .menu-connect-block{
      padding-left: 90px;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 40px;
      .connect-block-title{
        color: #5B5B5B;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
      .connect-email{
        color: #2E301B;
        font-family: Against;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        margin-top: 20px;
      }
      .connect-social-block{
        margin-top: 14px;
        svg{
          width: 45px;
          height: 45px;
          margin-right: 6px;
          &:hover{
            path{
              stroke:$brandColor;
            }
            circle{
              stroke:$brandColor;
            }
            rect{
              stroke:$brandColor;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    height: 100%;
    .left-menu-block{
      width: 80%;
      .menu-wrapper{
        margin-top: 20px;
        padding-left: 20px;
      }
      .menu-connect-block{
        padding-left: 20px;
        .connect-block-title{
          font-size: 18px;
        }
        .connect-email{
          font-size: 16px;
        }
      }
    }
  }
}