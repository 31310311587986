@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";


.home-about-wrapper{
  padding-top: 80px;
  width: 100%;
  height: 586px;
  display: flex;
  vertical-align: center;
  align-items: center;
  .home-about-left-block{
    width: 46%;
    height: 100%;
    padding-left: $mainPadding;
    background: #F3F3F3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .home-about-title{
      color: #2E301B;
      font-family: Against;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .home-about-description{
      width: 496px;
      color: #2E301B;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 140.9%;
      letter-spacing: -0.4px;
      margin-top: 20px;
    }
    .home-about-link{
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #6C584C;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 44px;
      cursor: pointer;
      svg{
        margin-left: 16px;
        transition: transform 0.2s;
        transform: rotate(0deg);
      }

      &:hover {
        svg {
          transform: rotate(0deg) translateX(6px);
        }
      }
    }
  }
  .home-about-right-block{
    width: 54%;
    height: 100%;
    .home-about-img{
      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: 1050px){
    height: unset;
    flex-direction: column-reverse;
    align-items: unset;
    .home-about-left-block{
      width: 92%;
      padding: 120px $mainPadding;
      .home-about-description{
        width: unset;
      }
    }
    .home-about-right-block{
      width: 100%;
      .home-about-img{
        width: unset;
        height: unset;
        position: relative;
        padding-top: 133.3%;
        img{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }
  }
}