@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.partners-wrapper {
  .partners-block {
    padding: 100px $mainPadding;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    .partner-item {
      width: calc(100% / 5 - 20px);
      border: 1px solid rgba(108, 88, 76, 0.20);
      height: 230px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .partners-hover-block {
        display: none;
      }

      &:hover {
        .partners-hover-block {
          text-decoration: unset;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #2E301B;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .text-count {
            color: #FFF;
            font-family: Against;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          svg {
            transition: transform 0.2s;
            transform: rotate(0deg);
            width: 40px;
          }

          &:hover {
            svg {
              transform: rotate(0deg) translateX(6px);
            }
          }
        }
      }

      .partner-item-img {
        width: 94%;

        img {
          width: 100%;
        }
      }
    }
  }

  .partner-joinUs-block {
    margin: 0 $mainPadding 120px $mainPadding;
    background: #2E301B;
    padding: 62px 46px;
    display: flex;
    justify-content: space-between;

    .joinUs-text-block {
      width: 40%;

      .joinUs-title {
        color: #F3F3F3;
        font-family: Against;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: -0.8px;
      }

      .joinUs-description {
        color: #F3F3F3;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 140.9%;
        letter-spacing: -0.4px;
        margin-top: 26px;
      }
    }

    .joinUs-inputs-block {
      width: 58%;

      .joinUs-info-inputs {
        margin-top: 40px;
      }

      .joinUs-fullName-inputs, .joinUs-info-inputs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .custom-input-group {
          width: 48%;
          label{
            color: #F3F3F3;
          }
          input {
            color: #F3F3F3;
            height: 50px;
            font-size: 22px;
            border: unset;
            border-bottom: 1px solid  #F3F3F3;;
          }
        }
      }

      .joinUs-send-btn {
        width: 258px;
        margin-top: 68px;
        height: 50px;
        padding: 15px 0;
        border: 1px solid #F3F3F3;
        color: #F3F3F3;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: transparent;
        span{
          margin-left: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .partners-block {
      padding: 76px $mainPadding;

      .partner-item {
        width: calc(100% / 2 - 20px);
        height: 120px;
      }
    }
    .partner-joinUs-block {
      margin: 0 0 100px 0;
      padding: 60px $mainPadding;
      flex-direction: column;

      .joinUs-text-block {
        width: 100%;
      }

      .joinUs-inputs-block {
        width: 100%;
        margin-top: 84px;

        .joinUs-info-inputs{
          margin-top: unset;
        }
        .joinUs-fullName-inputs, .joinUs-info-inputs {
          flex-direction: column;

          .custom-input-group {
            width: 100%;
            margin-bottom: 40px;
          }
        }
        .joinUs-send-btn{
          margin-top: 40px;
        }
      }
    }
  }
}