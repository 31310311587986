@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.our-brands-wrapper {
  padding: 120px $mainPadding 40px;

  .brands-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .brands-header-left {
      .brands-header-title {
        color: #2E301B;
        font-family: Against;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .brands-header-description {
        color: #5B5B5B;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.4px;
      }
    }

    .brands-header-right {
      color: #6C584C;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: unset;

      svg {
        margin-left: 16px;
        transition: transform 0.2s;
        transform: rotate(0deg);
      }

      &:hover {
        svg {
          transform: rotate(0deg) translateX(6px);
        }
      }
    }
  }

  .our-brandes-block {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;

    .brands-all-responsive {
      text-decoration: none;
      display: none;
    }

    .brands-item {
      width: calc(94% / 3);
      position: relative;

      .brandes-img {
        position: relative;
        padding-top: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .more-content {
        display: none;
      }

      &:hover {
        .more-content {
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.50);
          backdrop-filter: blur(2px);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .text-count {
            color: #FFF;
            font-family: Against;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          svg {
            transition: transform 0.2s;
            transform: rotate(0deg);
          }

          &:hover {
            svg {
              transform: rotate(0deg) translateX(6px);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .brands-header {
      .brands-header-left {
        width: 100%;
      }

      .brands-header-right {
        display: none;
      }
    }
    .our-brandes-block {
      margin-top: 60px;
      gap: unset;

      .brands-all-responsive {
        margin-top: 30px;
        color: #6C584C;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          margin-left: 16px;
          transition: transform 0.2s;
          transform: rotate(0deg);
        }

        &:hover {
          svg {
            transform: rotate(0deg) translateX(6px);
          }
        }

      }

      .brands-item {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}