@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";


.home-blog-wrapper{
  overflow: hidden;
  padding: 120px $mainPadding;
  .home-blog-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .home-blog-info{
      .home-blog-title{
        color: #2E301B;
        font-family: Against;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .home-blog-description{
        color: #5B5B5B;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.4px;
        margin-top: 20px;
      }
    }
    .home-blog-all{
      color: #6C584C;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: unset;
      svg{
        margin-left: 16px;
        transition: transform 0.2s;
        transform: rotate(0deg);
      }

      &:hover {
        svg {
          transform: rotate(0deg) translateX(6px);
        }
      }
    }
  }
  .home-blogs{
    width: 100%;
    margin-top: 100px;
    .blog-item{
      width: 100%;
    }
    .home-blog-all-responsive{
      color: #6C584C;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: none;
      align-items: center;
      cursor: pointer;
      text-decoration: unset;
      svg{
        margin-left: 16px;
        transition: transform 0.2s;
        transform: rotate(0deg);
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding: 120px 0;
    .home-blog-header{
      padding: 0 $mainPadding;
      .home-blog-all{
        display: none;
      }
    }
    .home-blogs{
      margin-top: 60px;
      padding-left: $mainPadding;
      .blog-item{
        width: 100%;
      }
      .home-blog-all-responsive{
        display: flex;
        margin-top: 56px;
      }
    }
  }
}