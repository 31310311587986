body {
  margin: 0;
  font-family: Montserrat, SpaceMono, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ant-tabs {
  .ant-tabs-nav {
    &:before {
      border-bottom: unset !important;
    }

    .ant-tabs-nav-wrap {
      display: flex;

      .ant-tabs-nav-list {
        display: flex;
        align-items: baseline;
        justify-content: center;

        .ant-tabs-ink-bar {
          display: none !important;
        }

        .ant-tabs-tab-active {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          justify-content: center;

          &::before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #2E301B;
            border-radius: 50%;
          }

          .ant-tabs-tab-btn {
            color: #2E301B !important;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 140.9%;
            letter-spacing: -0.48px;
          }
        }

        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            color: #5B5B5B;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 140.9%;
            letter-spacing: -0.48px;
          }
        }
      }
    }
  }
}
.ant-modal-footer{
  display: none;
}
:where(.css-dev-only-do-not-override-190m0jy).ant-tabs >.ant-tabs-nav .ant-tabs-nav-operations{
  display: none;
}
.ant-pagination-options{
  display: none!important;
}
@media screen and (max-width: 1050px){
  :where(.css-dev-only-do-not-override-190m0jy).ant-tabs >.ant-tabs-nav .ant-tabs-nav-wrap{
    overflow: unset;
  }
}
.layout-wrapper{
  overflow: hidden;
}
a{
  text-decoration: unset;
}