@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.contact-page-wrapper {
  .contacts-block {
    padding: 100px $mainPadding 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contact-info-wrapper {
      width: calc(48% - 178px);
      padding: 89px 68px;
      background-color: #F3F3F3;

      .contact-info-title {
        color: #2E301B;
        font-family: Against;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .contact-info-description {
        color: #2E301B;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      .contact-phone-block {
        text-decoration: unset;
        margin-top: 96px;
        display: flex;
        align-items: center;
        color: #2E301B;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        svg {
          width: 25px;
          height: 25px;
          margin-right: 20px;
        }
      }

      .contact-email-block, .contact-address-block {
        text-decoration: unset;
        margin-top: 54px;
        color: #2E301B;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;

        svg {
          width: 25px;
          height: 25px;
          margin-right: 20px;
        }
      }
    }

    .contact-inputs-wrapper {
      width: 48%;

      .top-input-block, .mid-input-block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .custom-input-group {
          width: 100%;

          input {
            height: 50px;
            border: unset;
            border-bottom: 1px solid rgba(46, 48, 27, 0.30);
          }
        }
      }

      .mid-input-block {
        margin: 40px 0;
      }

      .custom-input-group {
        input {
          height: 50px;
          border: unset;
          border-bottom: 1px solid rgba(46, 48, 27, 0.30);

          &::placeholder{
            color: #E8E8E8;
            font-family: Montserrat;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          &:not(.ant-select-search__field):focus {
            outline: none;
            border-color: #2E301B;
          }

          &.resizable {
            resize: vertical;
            min-height: 100px;
          }

          &.invalid {
            border-bottom: 1px solid #ff0000 !important;
          }
        }
      }

      .sent-email {
        margin-top: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5F4432;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding: 15px 50px;
        background-color: transparent;
        border: 1px solid #5F4432;
        cursor: pointer;
        span{
          margin-left: 10px;
        }

        &:hover{
          background-color:#5F4432 ;
          color: white;
        }
      }
    }

  }
  .contact-subscribe-block {
    padding: 100px $mainPadding;
  }

  @media screen and (max-width: 1050px) {
    .contacts-block {
      flex-direction: column;

      .contact-info-wrapper {
        width: unset;
        background-color: #2E301B;
        padding: 50px 68px;

        .contact-info-title{
          color: #FFF;
          font-family: Against;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .contact-info-description{
          color: #FFF;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin-top: 14px;
        }

        .contact-email-block, .contact-address-block, .contact-phone-block{
          color: #FFF;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          svg{
            path{
              fill: white;
            }
          }
        }
      }

      .contact-inputs-wrapper {
        width: 100%;
        margin: 60px 0;

        .top-input-block, .mid-input-block{
          flex-direction: column;
          .custom-input-group{
            width: 100%;
            input{
              width: 100%;
              margin-bottom: 40px;
            }
          }
        }
        .mid-input-block{
          margin: unset;
        }
        .custom-input-group{
          width: 100%;
          input{
            width: 100%;
          }
        }
      }
    }
    .contact-subscribe-block {
      padding: 100px 0;
    }
  }
}