@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.pages-header {
  background: #F3F3F3;
  padding: 50px $mainPadding;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pages-info {
    width: 652px;

    .pages-title {
      color: #2E301B;
      font-family: Against;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 110.377%;
    }

    .pages-description {
      color: #5B5B5B;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 140.9%;
      letter-spacing: -0.4px;
      margin-top: 36px;
    }
  }
  .pages-icon{
    width: 308px;
    position: relative;
    .animationArr-icon{
      position: absolute;
      top: 36%;
      left: 46%;
      transition: transform 0.2s;
      svg{
        animation: float 2s ease-in-out infinite;
      }
    }
    .animationBg-icon{
      transition: transform 0.2s;
      svg{
        animation: preloader 10s infinite linear;
      }
    }
  }

  @media screen and (max-width: 1050px){
    flex-direction: column;
    align-items: unset;
    .pages-info{
      width: unset;
      .pages-title{
        color: #2E301B;
        font-family: Against;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 110.377%;
      }
      .pages-description{
        color: #5B5B5B;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 140.9%;
        letter-spacing: -0.4px;
        margin-top: 24px;
      }
    }
    .pages-icon{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: 68px;
      width: unset;
      svg{
        width: unset;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes preloader {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(360deg);
  }
}