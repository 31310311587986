@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.home-partners-wrapper{
  background-color:$brandColor;
  padding:64px $mainPadding 44px $mainPadding;
  .home-partners-top-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .home-partners-title{
      width: 518px;
      color: #FFF;
      font-family: Against;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    .home-partners-info{
      width: 496px;
      .home-partners-description{
        color: #FFF;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 140.9%;
        letter-spacing: -0.4px;
      }
      .home-partners-btn{
        margin-top: 44px;
        .home-partners-joinUs{
          padding: 12px 60px;
          border: 1px solid #FFF;
          background: transparent;
          color: #FFF;
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          cursor: pointer;
          text-decoration: unset;
          &:hover{
            background-color: white;
            color:#2E301B;
          }
        }
        .home-partners-more{
          text-decoration: none;
          color: #FFF;
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin-left: 32px;
          background-color: transparent;
          border: unset;
          &:hover{
            color: #55573A;
          }
        }
      }
    }
  }
  .home-partners-bottom-block{
    margin-top: 110px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    .home-partners-item{
      width: calc(100% / 6 - 60px);
      cursor: pointer;
      padding: 20px 0;
      .home-partners-hover-img{
        display: none;
        position: relative;
        padding-top: 36.2%;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .home-partners-img{
        position: relative;
        padding-top: 36.2%;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &:hover{
        .home-partners-hover-img{
          display: flex;
        }
        .home-partners-img{
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .home-partners-top-block{
      flex-direction: column;
      .home-partners-title{
        width: unset;
        font-size: 30px;
      }
      .home-partners-info{
        width: unset;
        margin-top: 20px;
        .home-partners-btn{
          display: flex;
          align-items: center;
          .home-partners-joinUs{
            width:unset;
            padding: 12px 20px;
            font-size: 18px;
          }
          .home-partners-more{
            font-size: 18px;
          }
        }
      }
    }
    .home-partners-bottom-block{
      .home-partners-item{
        width: calc(100% / 2 - 20px);
      }
    }
  }
}