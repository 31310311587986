@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";


.main-slider-wrapper{
  margin: 10px $mainPadding;
  position: relative;
  .slider-item{
    position: relative;
    padding-top: 42.6%;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .slider-buttons-block{
    position: absolute;
    bottom: 40px;
    left: 40px;
    z-index: 1000;
    display: flex;
    align-items: center;
    .slider-btn{
      border: unset;
      background: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: unset;
      cursor: pointer;
      svg{
        path{
          stroke: $brandColor;
        }
        &:hover{
          path{
            stroke: rgba(46, 48, 27, 0.30);
          }
        }
      }
    }
    .next-btn{
      border-left: 1px solid #E9E9E9;
    }
  }

  @media screen and (max-width: 1050px){
    margin: unset;
    .slider-item{
      padding-top: 83.2%;
    }
    .slider-buttons-block{
      bottom: 30px;
      left: 20px;
      .slider-btn{
        padding: 8px;
      }
    }
  }
}