@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.bestseller-products{
  margin-top: 120px;
  width: 100%;
  .bestseller-products-title{
    color: #2E301B;
    font-family: Against;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.377%;
  }
  .bestseller-products-list{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 34px;
    .swiper{
      width: 100%;
      .bestseller-item{
        width: 96%;
      }
    }
  }
}