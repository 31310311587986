@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/mainVariables";

.product-card{
  width: 100%;
  text-decoration: unset;
  .product-card-img{
    position: relative;
    padding-top: 92.3%;
    overflow: hidden;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.7s;
      display: block;

      &:hover{
        transform: scale(1.2);
      }
    }
  }
  .product-card-info{
    margin-top: 20px;
    .product-card-title{
      color:#272727;
      text-align: center;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: -0.13px;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .product-card-description{
      color:#979797;
      text-align: center;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 300;
      line-height: 15px;
      letter-spacing: -0.13px;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}