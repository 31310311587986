@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/mainVariables";

.product-wrapper{
  .products-header{
    margin: 40px $mainPadding;
    position: relative;
    padding-top: 41.7%;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .product-block{
    padding: 30px $mainPadding;
    display: flex;
    overflow: hidden;
    .product-response-text{
      display: none;
    }
    .product-filter-block{
      width: 200px;
      margin-right: 120px;
      .filter-wrapper{
        .filter-block{
          width: 200px;
          .filter-title{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #5F4432;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: -0.16px;
            p{
              cursor: pointer;
            }
            &:hover{
              color:$brandColor;
              p{
                svg{
                  path{
                    fill:$brandColor;
                  }
                }
              }
            }
          }
          .filter-value{
            display: flex;
            align-items: center;
            color: #B79B7A;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: -0.14px;
            margin-bottom: 12px;
            cursor: pointer;
            &:hover{
              color:$brandColor;
            }
            svg{
              margin: 0 10px;
            }
          }
        }
      }
    }
    .product-list-block{
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 120px;
      .bestseller-products{
        width: calc(100% - 60px);
      }
      .product-info{
        .product-title{
          color: #2E301B;
          font-family: Against;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 110.377%;
        }
        .product-description{
          width: 80%;
          color: #5B5B5B;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 140.9%;
          letter-spacing: -0.4px;
          margin-top: 22px;
        }
      }
      .product-list-subtitle{
        margin-top: 114px;
        color: #2E301B;
        font-family: Against;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 110.377%;
      }
      button{
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset;
        background-color: transparent;
        span{
          font-size: 30px!important;
        }
      }
      .product-list{
        margin-top: 32px;
        width: calc(100% - 60px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        .product-list-items{
          width: 100%;
          display: flex;
          gap: 30px;
          align-items: center;
          flex-wrap: wrap;
          .product{
            width: calc(92% / 3);
            margin-bottom: 40px;
          }
        }
        .ant-pagination{
          display: flex;
          align-items: center;
          .ant-pagination-item{
            a{
              color:#8B8B8B;
              text-align: center;
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            &:hover{
              background-color: transparent;
              a{
                color: #2E301B;
              }
            }
          }
          .ant-pagination-item-active{
            border-color: transparent;
            a{
              color: #2E301B;
              text-align: center;
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
      .product-empty-block{
        width: 100%;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #9E9E9E;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 110.377%;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .products-header{
      margin: 20px 0;
      padding-bottom: 83.2%;
    }
    .product-block{
      flex-direction: column;
      .product-response-text{
        display: flex;
        flex-direction: column;
        .product-title{
          color: #2E301B;
          font-family: Against;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 110.377%;
        }
        .product-description{
          color: #5B5B5B;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 140.9%;
          letter-spacing: -0.4px;
          margin-top: 22px;
        }
      }
      .product-filter-block{
        margin-top: 60px;
        width: unset;
        margin-right: unset;
        .ant-collapse{
          width: unset;
        }
      }
      .product-list-block{
        width: 100%;
        align-items: center;
        margin-top: 50px;
        .product-info{
          display: none;
        }
        .product-list{
          flex-direction: column;
          flex-wrap: unset;
          .product{
            width: 100%;
          }
        }
      }
    }
  }
}