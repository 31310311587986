@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.header-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family:Against;
  padding: 42px $mainPadding;
  .burger-menu{
    width: 40px;
    height: 40px;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .header-link{
    text-decoration: unset;
    color: #2D411A;
    font-size: 40.548px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.377%;
    .header-logo-text{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .country{
        color: #5F4432;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.24px;
        margin: 0 4px;
      }
      .line{
        height: 0.5px;
        width: 38%;
        background-color: #D0C6BD;;
      }
    }
  }
  .lang-wrapper{
    width: 60px;
    display: flex;
    align-items: center;
    color: #2E301B;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.36px;
    cursor: pointer;
    .selected-language{
      display: flex;
      align-items: center;
    }
    span{
      width: 34px;
    }
    svg{
      margin-left: 4px;
    }
  }

  @media screen and (max-width: 600px){
    .burger-menu{
      width: 36px;
    }
    .header-link{
      color: #2E301B;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 110.377%;
      .header-logo-text{
        .country{
          font-size: 10px;
        }
      }
    }
    .lang-wrapper{
      .selected-language{

      }
    }
  }
}