@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";


.brandes-wrapper {
  //margin-bottom: 90px;
  .ant-tabs-nav-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .brandes-filter-block{
    padding: 80px $mainPadding 120px $mainPadding;
    .ant-pagination{
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin: 40px 0 20px 0;
      .ant-pagination-item{
        a{
          color:#8B8B8B;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &:hover{
          background-color: transparent;
          a{
            color: #2E301B;
          }
        }
      }
      .ant-pagination-item-active{
        border-color: transparent;
        a{
          color: #2E301B;
          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    .brands-block{
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      align-items: center;
      margin-top: 20px;
      .brand{
        width: calc(94% / 3);
        position: relative;
        .more-content {
          display: none;
        }

        &:hover {
          .more-content {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background:$brandColor;
            backdrop-filter: blur(2px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .text-count {
              color: #FFF;
              font-family: Against;
              font-size: 30px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 18px;
              display: flex;
              align-items: center;

              svg {
                transition: transform 0.2s;
                transform: rotate(0deg);
                margin-left:20px;
                width: 38px;
                height: 20px;
              }

              &:hover {
                svg {
                  transform: rotate(0deg) translateX(6px);
                }
              }
            }
          }
        }
        .brand-img{
          position: relative;
          padding-top: 100%;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .brands-all-responsive{
      display: none;
    }
  }

  @media screen and (max-width: 1050px){
    .brandes-filter-block{
      .brands-block{
        flex-wrap: unset;
        gap: unset;
        flex-direction: column;
        .brand{
          width: 100%;
          margin-bottom: 30px;
        }
      }
      .brands-all-responsive{
        margin-top: 30px;
        color: #6C584C;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin-left: 16px;
          transition: transform 0.2s;
          transform: rotate(0deg);
        }

        &:hover {
          img {
            transform: rotate(0deg) translateX(6px);
          }
        }
      }
    }
  }
}