@import "./src/assets/styles/mainVariables";


.custom-modal{
  overflow: hidden!important;
  :where(.css-dev-only-do-not-override-14wwjjs).ant-modal .ant-modal-content{
    background-color: black!important;
    background-clip: unset!important;
    box-shadow: unset!important;
  }
  .ant-modal{
    width: 70%!important;
    .ant-modal-content{
      padding: unset;
      border-radius: unset;
      .ant-modal-close{
        width: 26px;
        height: 26px;
        svg{
          width: 26px;
          height: 26px;
        }
        &:hover{
          svg{
            path{
              fill:$brandColor;
            }
          }
        }
      }
    }
  }
}
.slider-modal-wrapper{
  width: 100%;
  .modal-slider-wrapper{
    width: 100%;
    position: relative;
    padding-bottom: 73.5%;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      //border-radius: 20px;
    }
    @media screen and (max-width: 1050px){
      padding-bottom: 77%;
    }
  }
  .next-btn{
    border: unset;
    background: transparent;
    cursor: pointer;
    position: absolute;
    top: 46%;
    right: -8%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    svg{
      path{
        stroke: $brandColor;
      }
      &:hover{
        path{
          stroke: rgba(46, 48, 27, 0.30);
        }
      }
    }
  }
  .prev-btn{
    border: unset;
    background: transparent;
    cursor: pointer;
    position: absolute;
    z-index: 6;
    top: 46%;
    left: -8%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      path{
        stroke: $brandColor;
      }
      &:hover{
        path{
          stroke: rgba(46, 48, 27, 0.30);
        }
      }
    }
  }

}

@media screen and (max-width: 1050px){
  .custom-modal{
    .ant-modal{
      width: 86%!important;
    }
  }
  .slider-modal-wrapper{
    .next-btn{
      width: 40px;
      height: 40px;
      right: -8%;
    }
    .prev-btn{
      width: 40px;
      height: 40px;
      left: -8%;
    }
  }
}