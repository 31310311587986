@import "../../../../../assets/styles/fonts";
@import "../../../../../assets/styles/mainVariables";

.product-detail-page{
  border-top: 1px solid #D0C6BDB2;
  .product-detail-wrapper{
    padding: 120px $mainPadding 60px;
    display: flex;
    justify-content: space-between;
    .product-detail-slider{
      width: 49%;
      .mySwiper2{
        width: calc(100% - 134px);
        .slider-single-img{
          position: relative;
          padding-top: 90.5%;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .slider-bottom-side{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .slider-left-btn, .slider-right-btn{
          border: unset;
          background-color: transparent;
          cursor: pointer;
          &:hover{
            svg{
              path{
                stroke:#B9B9B9;
              }
            }
          }
        }
        .slider-left-btn{
          rotate: 180deg;
          margin-right: 6px;
        }
        .slider-right-btn{
          margin-left: 6px;
        }
        .mySwiper{
          width: calc(100% - 134px);
          margin-top: 12px;
          .placeholderImg{
            position: relative;
            padding-top: 105.5%;
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .active-img{
            border: 1px solid $brandColor;
          }
          .slider-bottom-images{
            position: relative;
            padding-top: 100%;
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    .product-detail-info{
      width: 46%;
      .product-detail-name{
        color: #2E301B;
        font-family: Against;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 110.377%;
      }
      .product-detail-description{
        color: #5B5B5B;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 140.9%;
        letter-spacing: -0.4px;
        margin-top: 22px;
      }
      .ant-tabs{
        margin-top: 60px;
        width: 100%;
        .ant-tabs-nav{
          width: 100%;
          .ant-tabs-nav-wrap{
            width: 100%;
            .ant-tabs-nav-list{
              border-bottom: 1px solid #E7E7E7;
              display: flex;
              justify-content: space-between;
              .ant-tabs-tab{
                .ant-tabs-tab-btn{
                  color: #A59992;
                  font-family: Montserrat;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 140.9%;
                  letter-spacing: -0.36px;
                }
              }
            }
          }
        }
      }
      .product-detail-tabs-info{
        color: #2E301B;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140.9%;
        letter-spacing: -0.32px;
        margin-top: 24px;
      }
      .product-detail-buy-block{
        margin-top: 80px;
        width: calc(100% - 84px);
        padding: 34px 41px 30px 43px;
        background: #F3F3F3;
        .buy-block-title{
          color: #2E301B;
          font-family: Montserrat;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 140.9%;
          letter-spacing: -0.52px;
        }
        .buy-block-description{
          color: #5B5B5B;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 140.9%;
          letter-spacing: -0.4px;
          margin-top: 8px;
        }
        .buy-block-shops{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 54px;
          .buy-block-img-wrapper{
            width: calc(48% - 48px);
            background-color: #2E301B;
            padding: 14px 24px;
            &:hover{
              background-color: #47482BFF;
            }
            .buy-block-img{
              cursor: pointer;
              padding-top: 27.8%;
              position: relative;
              img{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                object-fit: cover;
              }
            }
          }
          .buy-block-ozon{
            width: 48%;
            height: 64px;
            background-color: #2E301B;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-size: 26.972px;
            font-style: normal;
            font-weight: 600;
            line-height: 34.678px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .best-seller-wrapper{
    padding: 0 $mainPadding;
    margin-bottom: 44px;
  }

  @media screen and (max-width: 1050px){
    overflow: hidden;
    .product-detail-wrapper{
      flex-direction: column;
      padding: 38px $mainPadding;
      .product-detail-slider{
        width: 100%;
      }
      .product-detail-info{
        width: 100%;
        margin-top: 80px;
        .product-detail-buy-block{
          width: calc(100% - 40px);
          padding: 38px 20px;
        }
      }
    }
  }
}