@font-face {
  font-family: Against;
  src: url(../fonts/against-regular.ttf) format('truetype') ;
  font-weight: 300 400;
}

@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: 300 400;
}

@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500 600;
}

@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700 900;
}