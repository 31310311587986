@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/mainVariables";

.blog-card-wrapper{
  width: 100%;
  text-decoration: unset;
  .blog-card-img{
    position: relative;
    padding-top: 125%;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .blog-card-info{
    margin-top: 24px;
    .blog-card-title{
      color: #2E301B;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.48px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .blog-card-description{
      margin-top: 8px;
      color: #5B5B5B;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.36px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  &:hover{
    .blog-card-info{
      .blog-card-title{
        color:#6C584C;
      }
    }
  }
}