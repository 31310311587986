
:root {
  --brandColor: #2E301B;
  --brandSecColor: #F3F3F3;
  --mainPadding: 120px;
  --mainContent: 1440px;
  --halfContent: calc(50% - 100px);
  --headerHeight: 72px;
  --elementMainHeight: 42px;
}

$mainPadding: var(--mainPadding);
$mainContent: var(--mainContent);
$halfContent: var(--halfContent);
$headerHeight: var(--headerHeight);
$elementMainHeight: var(--elementMainHeight);

//--------Colors---------

$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$bgColor: #F3F3F3;
$darkColor: #1F2226;
$greyColor: #F4F4F4;
$darkGreyColor: #E2E2E2;
$borderColor: #E0E0E0;
$textColor: #2E301B;
$greyTextColor: #202020;
$redColor: #ef5151;

@media (max-width: 1700px) {
  :root {
    --asideContentWidth: 430px;
  }
}

@media (max-width: 1500px) {
  :root {
    --asideContentWidth: 380px;
    --mainPadding: 110px;
  }
}

@media (max-width: 1400px) {
  :root {
    --mainPadding: 100px;
  }
}

@media (max-width: 1300px) {
  :root {
    --mainPadding: 40px;
    --asideContentWidth: 300px;
  }
}

@media (max-width: 1100px) {
  :root {
    --mainPadding: 30px;
  }
}


@media (max-width: 800px) {
  :root {
    --asideContentWidth: 250px;
    --mainPadding: 20px;
  }
}

@media (max-width: 600px) {
  :root {
    --mainPadding: 15px;
  }
}

