@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";


.blog-page-wrapper{
  .blogs-wrapper{
    padding: 100px $mainPadding;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    .blog-item{
      width: calc(100% / 3 - 30px);
      margin-bottom: 30px;
    }
  }
  .ant-pagination{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    .ant-pagination-item{
      a{
        color:#8B8B8B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &:hover{
        background-color: transparent;
        a{
          color: #2E301B;
        }
      }
    }
    .ant-pagination-item-active{
      border-color: transparent;
      a{
        color: #2E301B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .blog-subscribe{
    padding: 120px $mainPadding;
  }

  @media screen and (max-width: 1050px){
    .blogs-wrapper{
      flex-direction: column;
      flex-wrap: unset;
      gap: unset;
      .blog-item{
        width: 100%;
      }
    }
    .blog-subscribe{
      padding: 20px 0 120px 0;
    }
  }
}