@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";


.subscribe-block {
  background: #2E301B;
  padding: 88px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .subscribe-title {
    width: 56%;
    color: #FFF;
    text-align: center;
    font-family: Against;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.8px;
  }
  .subscribe-description{
    width: 56%;
    color: #5B5B5B;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 22px;
  }
  .subscribe-input-block{
    width: 56%;
    display: flex;
    align-items: center;
    margin-top: 48px;
    padding-left: 6px;
    .custom-input-group{
      width: calc(100% - 236px);
      background-color: white;
      input{
        width: 100%;
        height: 56px;
        padding: unset;
        border-right: unset;
        font-size:22px;
        padding-left: 10px;

        &:not(.ant-select-search__field):focus {
          outline: none;
          border-color: white;
        }

        &.invalid {
          border: 1px solid #ff0000!important;
        }

        &::placeholder{
          padding-left: 6px;
        }
      }
    }
    .sent-email{
      z-index: 22;
      width: 236px;
      background-color:#55573A;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFF;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      border: unset;
      cursor: pointer;
      span{
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding: 60px 32px;
    .subscribe-title{
      width: 100%;
      color: #FFF;
      text-align: center;
      font-family: Against;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.8px;
    }
    .subscribe-description{
      width: 100%;
      color: white;
    }
    .subscribe-input-block{
      width: 100%;
      flex-direction: column;
      .sent-email{
        width: 100%;
        margin-top: 20px;
        font-size: 18px;
      }
      .custom-input-group{
        width: 100%;
        input{
          padding-left: unset;
          font-size: 18px;

          &::placeholder{
            font-size: 18px;
          }
        }
      }
    }
  }
}