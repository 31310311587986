@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";


.footer-wrapper {
  background: #F3F3F3;

  .responsive-footer-link{
    display: none;
    text-decoration: unset;
    color: #2E301B;
    font-family: Against;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.377%;
    .responsive-footer-logo-text{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .country{
        color: #5F4432;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.24px;
        margin: 0 4px;
      }
      .line{
        height: 0.5px;
        width: 38%;
        background-color: #D0C6BD;;
      }
    }
  }
  .footer-top-block {
    padding: 38px $mainPadding 32px $mainPadding;
    display: flex;
    justify-content: space-between;

    .footer-logo{
      svg{
        width: 118px;
      }
    }

    .col {
      display: flex;
      flex-direction: column;

      .selected-language{
        color: #2E301B !important;
        font-family: Montserrat !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.36px;
        display: flex;
        align-items: center;
        padding-bottom: unset;
      }

      a {
        color: #2E301B;
        font-family: against;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.44px;
        text-decoration: unset;
        padding-bottom: 20px;
        &:hover{
          color: #6C584C;
        }
      }

      .footer-brands-title {
        color: #2E301B;
        font-family: against;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.44px;
      }
      .footer-brand-all{
        margin-top: 28px;
        color: #6C584C;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg{
          width: 30px;
          margin-left: 6px;
          transition: transform 0.2s;
          transform: rotate(0deg);
        }

        &:hover {
          svg {
            transform: rotate(0deg) translateX(6px);
          }
        }
      }
      .footer-brand-name{
        width: 156px;
        color: #5B5B5B;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.36px;
        margin-top: 16px;
        padding-bottom: unset;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //-webkit-box-orient: vertical;
        &:hover{
          color: #2E301B;
        }
      }
      .medias-wrapper{
        .footer-medias-title{
          color: #2E301B;
          font-family: Against;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.44px;
        }
        .footer-medias{
          margin-top: 14px;
          svg{
            width: 45px;
            height: 45px;
            margin-right: 6px;
            &:hover{
              path{
                stroke:$brandColor;
              }
              circle{
                stroke:$brandColor;
              }
              rect{
                stroke:$brandColor;
              }
            }
          }
        }
      }
      .footer-lang{
        margin-top: 24px;
        display: flex;
        align-items: center;
        color: #2E301B;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.36px;
        cursor: pointer;
        a{
          padding-bottom: unset;
        }
        svg{
          width: 18px;
          height: 18px;
          margin-left: 4px;
        }
      }
    }
  }
  .footer-bottom-block{
    padding: 20px 0;
    border-top: 1px solid rgba(208, 198, 189, 0.70);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(46, 48, 27, 0.42);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    a{
      text-decoration: none;
      color: #2E301B;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-left: 4px;
      &:hover{
        color: #6C584C;
      }
    }
  }

  @media screen and (max-width: 1050px){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .responsive-footer-link{
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .footer-top-block{
      flex-wrap: wrap;
      .footer-logo{
        display: none;
      }
      .col{
        &:last-child{
          width: 74%;
          margin-top: 100px;
          display: flex;
          align-items: baseline;
          flex-direction: unset;
          justify-content: space-between;
          .medias-wrapper{
            //width: q0%;
          }
        }
      }
    }
    .footer-bottom-block{
      padding: 20px $mainPadding;
      flex-direction: column;
      display: unset;
      font-size: 13px;
      text-align: center;
      a{
        font-size: 14px;
      }
    }
  }
}